import React, { useState } from "react";
import training from "../../assets/training.PNG"
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const ProgramList = () => {
  const programs = [
    {
      title: "HABC Level 2 Award in Fire Safety",
      image: training,
      link:"/training-details",
      dates: [
        { range: "Jan 23 - Jan 23, 2025" },
        { range: "Feb 20 - Feb 20, 2025" },
        { range: "Mar 10 - Mar 10, 2025" },
      ],
    },
    {
      title: "HABC Level 1 International Award in Fire Safety",
      image: training,
      link:"/training-details",
      dates: [
        { range: "Jan 22 - Jan 22, 2025" },
        { range: "Feb 5 - Feb 5, 2025" },
      ],
    },
    {
      title: "HABC Level 2 Award in the Control of Substances Hazardous to Health",
      image: training,
        link:"/training-details",
      dates: [{ range: "Sep 1 - Sep 1, 2025" }],
    },
    {
        title: "HABC Level 2 Award in Fire Safety",
        image: training,
        link:"/training-details",
        dates: [
          { range: "Jan 23 - Jan 23, 2025" },
          { range: "Feb 20 - Feb 20, 2025" },
          { range: "Mar 10 - Mar 10, 2025" },
        ],
      },
      {
        title: "HABC Level 1 International Award in Fire Safety",
        image: training,
        link:"/training-details",
        dates: [
          { range: "Jan 22 - Jan 22, 2025" },
          { range: "Feb 5 - Feb 5, 2025" },
        ],
      },
      {
        title: "HABC Level 2 Award in the Control of Substances Hazardous to Health",
        image: training,
         link:"/training-details",
        dates: [{ range: "Sep 1 - Sep 1, 2025" }],
      },
  ];

  const [showAllDates, setShowAllDates] = useState({});

  const toggleShowMore = (index) => {
    setShowAllDates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleLinkClick = () => {
      scroll.scrollToTop();
    };
  const handleRegister = (title,range) => {
    const registrationDetails = {
      title: title,
      date: range,
      time: " ",
      venue: " ",
      fees: " ",
    };
    localStorage.setItem("registrationDetails", JSON.stringify(registrationDetails));
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 p-8">
      {programs.map((program, index) => (
        <div
          key={index}
          className="bg-white border rounded-lg shadow-lg p-4 w-full"
        >
          <img
            src={program.image}
            alt={program.title}
            className="h-40 w-full object-cover rounded-t-lg"
          />
          <Link to={program.link}>
          <h3 className="text-lg font-semibold mt-4">{program.title}</h3>
          </Link>
          <Link to={program.link} onClick={handleLinkClick}>
          <button className="bg-black hover:bg-orange-600 duration-300 ease-in-out text-white py-2 px-4 rounded-lg mt-4">
            Program Details
          </button>
          </Link>
          <div className="mt-4">
            <h4 className="text-gray-500 font-semibold">Available Dates</h4>
            <ul className="mt-2">
              {program.dates
                .slice(0, showAllDates[index] ? program.dates.length : 2)
                .map((date, dateIndex) => (
                  <li
                    key={dateIndex}
                    className="flex justify-between items-center mt-2 text-sm"
                  >
                    <span className="flex items-center">
                      <span className="material-icons text-gray-500 mr-2">
                        calendar_today
                      </span>
                      {date.range}
                    </span>
                    <Link to="/training-registration" onClick={handleLinkClick}>
                    <button className="bg-gray-200 hover:bg-black hover:text-white duration-300 ease-in-out text-gray-800 py-1 px-2 rounded-lg" onClick={() => handleRegister(program.title,date.range)}>
                      Register Now
                    </button>
                    </Link>
                  </li>
                ))}
            </ul>
            {program.dates.length > 2 && (
                <div className="flex justify-end">
               <button
               onClick={() => toggleShowMore(index)}
               className="mt-4 text-[12px]   text-white hover:bg-purple-600 duration-300 ease-in-out bg-black/40 py-2 px-2  rounded-lg flex items-center"
             >
               {showAllDates[index] ? "Show less" : "Show more"}
               <span className="material-icons text-[14px] ml-1">
                 {showAllDates[index] ? "expand_less" : "expand_more"}
               </span>
             </button>
             </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgramList;
