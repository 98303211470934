import React from "react";
import Layout from "../../components/Layout";
import TrainingSection1 from "./TrainingSection1";
import ProgramList from "./ProgramList";

const Trainings = () => {
  return (
    <>
    <Layout
      title={"MBA Solutions - training "}
      keywords={
        "Track, training, MBA Solutions ,MBA Solutions training, Training Information"
      }

    >

<TrainingSection1 />
<ProgramList />

    </Layout>
    </>
  );
};

export default Trainings;
