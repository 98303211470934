import React from 'react'
import Layout from '../../components/Layout'

const Sustainability = () => {
  return (
    <Layout>
    <div className="bg-black h-[100vh] w-full flex justify-center items-center font-bold text-white ">This Page is under Construction</div>
    </Layout>
  )
}

export default Sustainability