import React from "react";
import training from "../../assets/training.PNG"
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import { FaMapMarkerAlt, FaMoneyBillWave } from "react-icons/fa";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
const SingleProgramPage = () => {
  const program = {
    title: "HABC Level 2 Award in Fire Safety",
    image: training,
    details: {
      description:
        "The Level 2 Award in Fire Safety is designed to provide essential knowledge for fire safety in workplaces.",
      qualificationDetails:
        "This qualification is regulated by relevant authorities and part of the Qualification and Credit Framework (QCF).",
      qualificationStructure: [
        "Understand fire safety and what to do in case of emergencies.",
        "Learn how to identify fire hazards and implement control measures.",
      ],
      learningOutcome: [
        "Identify fire safety risks and understand emergency procedures.",
        "Apply fire prevention principles effectively in workplace settings.",
      ],
    },
    dates: [
      {
        range: "January 23, 2025",
        time: "08:00 - 16:00",
        venue: "Classroom 1",
        fees: "70 BHD",
      },
      {
        range: "February 20, 2025",
        time: "08:00 - 16:00",
        venue: "Classroom 1",
        fees: "70 BHD",
      },
    ],
  };
  const handleRegister = (date) => {
    const registrationDetails = {
      title: program.title,
      date: date.range,
      time: date.time,
      venue: date.venue,
      fees: date.fees,
    };
    localStorage.setItem("registrationDetails", JSON.stringify(registrationDetails));
        scroll.scrollToTop();
  };

  return (
    <Layout title={"MBA Solutions - training "} keywords={"Track, training, MBA Solutions ,MBA Solutions training, Training Information"}>
    <div className="sectionlayout">
      <div className="grid grid-cols-1 lg:grid-cols-3 my-20 gap-8">
        {/* Left Side: Program Description */}
        <div className="bg-white shadow-lg lg:col-span-2 rounded-lg p-6 border">
          <img
            src={program.image}
            alt={program.title}
            className="w-full h-60 object-cover rounded-lg mb-6"
          />
          <h1 className="text-2xl font-bold mb-4">{program.title}</h1>
          <p className="text-gray-700 mb-4">{program.details.description}</p>
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Qualification Details</h2>
            <p className="text-gray-600">{program.details.qualificationDetails}</p>
          </div>
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Qualification Structure</h2>
            <ul className="list-disc list-inside text-gray-600">
              {program.details.qualificationStructure.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Learning Outcomes</h2>
            <ul className="list-disc list-inside text-gray-600">
              {program.details.learningOutcome.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 border">
          <h2 className="text-2xl font-bold my-6 text-center ">Available Dates</h2>
          <div className="space-y-8">
            {program.dates.map((date, index) => (
              <div
                key={index}
                className="bg-white rounded-lg px-4 py-6 shadow-md border flex flex-col space-y-2 "
              >
                <p className="flex items-center text-sm font-medium mb-2">
                  <AiOutlineCalendar size={16} className="mr-2 text-gray-700" />
                  <strong>Starting:</strong> {date.range}
                </p>
                <p className="flex items-center text-sm pb-2">
                  <AiOutlineClockCircle  size={16} className="mr-2 text-gray-700" />
                  <strong>Time:</strong> {date.time}
                </p>
                <p className="flex items-center text-sm pb-2">
                  <FaMapMarkerAlt size={16} className="mr-2 text-gray-700" />
                  <strong>Venue:</strong> {date.venue}
                </p>
                <p className="flex items-center text-sm pb-2">
                  <FaMoneyBillWave size={16} className="mr-2 text-gray-700" />
                  <strong>Total Fees:</strong> {date.fees}
                </p>
                <Link to="/training-registration" className="text-center" >
                <button className="mt-10 hover:bg-orange-500 duration-300 font-semibold ease-in-out transition-all bg-black text-white py-2 px-4 rounded-lg w-full"  onClick={() => handleRegister(date)}>
                  Register Now
                </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};


export default SingleProgramPage;
