import React from 'react'

function TrainingSection1() {
const courses = [
    "ASSP Courses", "BSC", "IOSH Courses", "Medic Courses",
    "Highfield Courses", "NOCN Courses", "BQA Courses", "NPORS", "CII Courses",
    "CIPS Courses", "Al Mashreq Training Courses", "Job Seekers"
];

return (
    <div>
        <div className=' mt-8'>
            <div className='bg-[#F2F2F2] sectionlayout '>
                <div className='py-32 px-5'>
                <div className="flex font-bold justify-center text-[22px] cursor-pointer flex-wrap gap-4 md:gap-10">
                    {courses.map((course, index) => (
                        <div key={index} className="hover:underline cursor-pointer">
                            {course}
                        </div>
                    ))}
                </div>
                </div>
            </div>



        </div>
    </div>
)
}

export default TrainingSection1