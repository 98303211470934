import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

const RegisterForm = () => {
  const [registrationDetails, setRegistrationDetails] = useState(() => {
    const savedDetails = localStorage.getItem("registrationDetails");
    return savedDetails
      ? JSON.parse(savedDetails)
      : {
          title: " - ",
          date: " - ",
          time: " - ",
          venue:    " - ",
          fees: " - ",
        };
  });

  useEffect(() => {
    localStorage.setItem(
      "registrationDetails",
      JSON.stringify(registrationDetails)
    );
  }, [registrationDetails]);
  const [registrationType, setRegistrationType] = useState("");
  const [candidates, setCandidates] = useState([]);

  const handleRegistrationTypeChange = (type) => {
    setRegistrationType(type);
  };

  const handleAddCandidate = () => {
    setCandidates([
      ...candidates,
      { id: Date.now(), name: "", email: "", cpr: "", contact: "", dob: "" },
    ]);
  };

  // Remove candidate
  const handleRemoveCandidate = (id) => {
    setCandidates(candidates.filter((candidate) => candidate.id !== id));
  };

  // Handle candidate input change
  const handleCandidateChange = (id, field, value) => {
    setCandidates(
      candidates.map((candidate) =>
        candidate.id === id ? { ...candidate, [field]: value } : candidate
      )
    );
  };
  return (
    <Layout
      title={"MBA Solutions - Register "}
      keywords={
        "Track, training, MBA Solutions ,MBA Solutions training, Training Information"
      }
    >

    

<div className="bg-[#f7f7f7] ">
<div className=" mt-10  sectionlayout ">

    <h2 className="font-bold drop-shadow-md text-[24px] my-6 pt-8">Program Information</h2>
    <p className="text-gray-700 mb-4 " ><span className="font-bold">Course Name : </span> {registrationDetails.title}</p> 
            <p className="text-gray-700 mb-4"><span className="font-bold">Course Date : </span> {registrationDetails.date}</p>
            <p className="text-gray-700 mb-4"><span className="font-bold">Course Time : </span> {registrationDetails.time}</p>
            <p className="text-gray-700 mb-4"><span className="font-bold">Course Venue : </span>{registrationDetails.venue}</p>
            <p className="text-gray-700 mb-4 pb-8"><span className="font-bold">Course Fees : </span> {registrationDetails.fees}</p>
                
</div>
</div>
<div className="  sectionlayout">

        <form className="space-y-6 mt-20">
          {/* Candidate Name and National ID */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="candidateName"
                className="block text-sm font-medium text-gray-700"
              >
                Candidate Name *
              </label>
              <input
                type="text"
                id="candidateName"
                placeholder="Enter your name"
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                required
              />
            </div>
            <div>
              <label
                htmlFor="nationalId"
                className="block text-sm font-medium text-gray-700"
              >
                CPR/National ID No.
              </label>
              <input
                type="text"
                id="nationalId"
                placeholder="Enter your National ID"
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
              />
            </div>
          </div>

          {/* Email Address and Mobile Number */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address *
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                required
              />
            </div>
            <div>
              <label
                htmlFor="mobileNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number *
              </label>
              <input
                type="text"
                id="mobileNumber"
                placeholder="Enter your mobile number"
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                required
              />
            </div>
          </div>

          {/* Registration Type */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Registration Type *
            </label>
            <div className="space-y-2 mt-2">
              <div className="flex items-center">
                <input
                  id="individual"
                  name="registrationType"
                  type="radio"
                  required
                  value="Individual Registration"
                  className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring focus:ring-indigo-300"
                  onChange={() => handleRegistrationTypeChange("Individual")}
                />
                <label htmlFor="individual" className="ml-2 text-gray-700">
                  Individual Registration
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="corporate"
                  name="registrationType"
                  type="radio"
                  value="Corporate Registration"
                  className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring focus:ring-indigo-300"
                  onChange={() => handleRegistrationTypeChange("Corporate")}
                />
                <label htmlFor="corporate" className="ml-2 text-gray-700">
                  Corporate Registration
                </label>
              </div>
            </div>
          </div>

          {/* Conditionally Rendered Inputs */}
          {registrationType === "Individual" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="dateOfBirth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="dateOfBirth"
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                />
              </div>
              <div>
                <label
                  htmlFor="otherPhone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Other Phone
                </label>
                <input
                  type="text"
                  id="otherPhone"
                  placeholder="Enter other phone number"
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                />
              </div>
            </div>
          )}
          {registrationType === "Corporate" && (
            <div>
              <div className="grid grid-cols-1  gap-4">
                <div>
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    placeholder="Enter company name"
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                  />
                </div>
                <div>
                  <label
                    htmlFor="managerName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Training Manager Name
                  </label>
                  <input
                    type="text"
                    id="managerName"
                    placeholder="Enter training manager name"
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 mt-4 sm:grid-cols-2 gap-x-4 gap-y-6">
                <div>
                  <label
                    htmlFor="mobilePhone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile Phone
                  </label>
                  <input
                    type="text"
                    id="mobilePhone"
                    placeholder="Enter mobile phone"
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                  />
                </div>
                <div>
                  <label
                    htmlFor="workPhone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Work Phone
                  </label>
                  <input
                    type="text"
                    id="workPhone"
                    placeholder="Enter work phone"
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 mt-4 sm:grid-cols-2 gap-x-4 gap-y-6">
                <div>
                  <label
                    htmlFor="workemail"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Work Email
                  </label>
                  <input
                    type="email"
                    id="workemail"
                    placeholder="Email"
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                  />
                </div>
                <div>
                  <label
                    htmlFor="pobox"
                    className="block text-sm font-medium text-gray-700"
                  >
                    P.O.Box
                  </label>
                  <input
                    type="text"
                    id="pobox"
                    placeholder="Enter P.O.Box"
                    className="mt-1 block w-full p-3 border border-gray-300 focus:border-none rounded-md focus:ring focus:ring-indigo-300"
                  />
                </div>
              </div>
            </div>
          )}
          {registrationType === "Corporate" && (
            <>
              <h2 className="text-lg font-bold text-gray-800 mt-6">
                Candidates Registration
              </h2>
              {candidates.map((candidate, index) => (
                <div key={candidate.id} className="  mt-4 py-4 relative ">
                  <button
                    type="button"
                    className="absolute top-0 right-2 text-red-600"
                    onClick={() => handleRemoveCandidate(candidate.id)}
                  >
                    ✕
                  </button>
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor={`candidateName${index}`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Candidate Name
                      </label>
                      <input
                        type="text"
                        id={`candidateName${index}`}
                        placeholder="Enter candidate name"
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                        value={candidate.name}
                        onChange={(e) =>
                          handleCandidateChange(
                            candidate.id,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`candidateEmail${index}`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id={`candidateEmail${index}`}
                        placeholder="Enter email"
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                        value={candidate.email}
                        onChange={(e) =>
                          handleCandidateChange(
                            candidate.id,
                            "email",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                    <div>
                      <label
                        htmlFor={`candidateCpr${index}`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        CPR/National ID
                      </label>
                      <input
                        type="text"
                        id={`candidateCpr${index}`}
                        placeholder="Enter CPR/National ID"
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                        value={candidate.cpr}
                        onChange={(e) =>
                          handleCandidateChange(
                            candidate.id,
                            "cpr",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`candidateContact${index}`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact Number
                      </label>
                      <input
                        type="text"
                        id={`candidateContact${index}`}
                        placeholder="Enter contact number"
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                        value={candidate.contact}
                        onChange={(e) =>
                          handleCandidateChange(
                            candidate.id,
                            "contact",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`candidateDob${index}`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        id={`candidateDob${index}`}
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-indigo-300"
                        value={candidate.dob}
                        onChange={(e) =>
                          handleCandidateChange(
                            candidate.id,
                            "dob",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="mt-4 px-4 py-2 bg-yellow-400 text-white rounded-md hover:bg-yellow-500 focus:ring focus:ring-yellow-300"
                onClick={handleAddCandidate}
              >
                Add New Candidate
              </button>
            </>
          )}

          {/* Terms & Conditions */}
          <div>
            <div className="flex items-center">
              <input
                id="terms"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring focus:ring-indigo-300"
                required
              />
              <label htmlFor="terms" className="ml-2 text-gray-700">
                I Agree to the{" "}
                <Link to="/terms" className="text-red-500 hover:underline">
                  Terms & Conditions
                </Link>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className=" bg-orange-500 w-[170px] font-semibold text-white py-3 px-4 rounded-md hover:bg-orange-600 "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default RegisterForm;
